






import { Component, Vue } from 'vue-property-decorator';
import Slider from './components/Slider.vue';

@Component({
  components: {
    Slider,
  },
})
export default class App extends Vue {}
